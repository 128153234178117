"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const vue_1 = require("vue");
const vue_2 = require("vue");
const _withScopeId = n => ((0, vue_2.pushScopeId)("data-v-7ceb0aea"), n = n(), (0, vue_2.popScopeId)(), n);
const _hoisted_1 = { key: 0 };
const _hoisted_2 = {
    key: 1,
    class: "title-box"
};
const vue_3 = require("vue");
const naive_ui_1 = require("naive-ui");
const material_1 = require("@vicons/material");
exports.default = (0, vue_1.defineComponent)({
    props: {
        title: {
            type: String,
            default: ''
        }
    },
    emits: ["item-update", "item-del"],
    setup(__props, { emit }) {
        const props = __props;
        const editing = (0, vue_3.ref)(false);
        const editingTitle = (0, vue_3.ref)('');
        const inputRef = (0, vue_3.ref)(null);
        function handleClickEdit() {
            editingTitle.value = props.title;
            editing.value = true;
            (0, vue_3.nextTick)(() => {
                inputRef.value.focus();
            });
        }
        let bluring = false;
        function handleBlur() {
            if (bluring) {
                return;
            }
            bluring = true;
            setTimeout(() => {
                bluring = false;
            }, 1000);
            if ((editingTitle.value !== props.title) || props.title === '') {
                emit('item-update', editingTitle.value);
            }
            editing.value = false;
        }
        (0, vue_3.watch)(() => props.title, (title) => {
            if (title) {
                return;
            }
            (0, vue_3.nextTick)(() => {
                inputRef.value.focus();
            });
        }, { immediate: true });
        return (_ctx, _cache) => {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                (!__props.title || editing.value)
                    ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                        (0, vue_2.createVNode)((0, vue_2.unref)(naive_ui_1.NInput), {
                            ref: (_value, _refs) => {
                                _refs['inputRef'] = _value;
                                inputRef.value = _value;
                            },
                            size: "small",
                            maxlength: "100",
                            value: editingTitle.value,
                            "onUpdate:value": _cache[0] || (_cache[0] = ($event) => ((editingTitle).value = $event)),
                            onBlur: handleBlur,
                            onKeyup: (0, vue_2.withKeys)(handleBlur, ["enter"])
                        }, null, 8, ["value", "onKeyup"])
                    ]))
                    : ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_2, [
                        (0, vue_2.createElementVNode)("span", null, (0, vue_2.toDisplayString)(__props.title), 1),
                        (0, vue_2.createElementVNode)("div", {
                            class: "btns",
                            onClick: _cache[2] || (_cache[2] = (0, vue_2.withModifiers)(() => { }, ["stop"]))
                        }, [
                            (0, vue_2.createVNode)((0, vue_2.unref)(naive_ui_1.NIcon), { onClick: handleClickEdit }, {
                                default: (0, vue_2.withCtx)(() => [
                                    (0, vue_2.createVNode)((0, vue_2.unref)(material_1.EditFilled))
                                ]),
                                _: 1
                            }),
                            (0, vue_2.createVNode)((0, vue_2.unref)(naive_ui_1.NIcon), {
                                onClick: _cache[1] || (_cache[1] = ($event) => (emit('item-del')))
                            }, {
                                default: (0, vue_2.withCtx)(() => [
                                    (0, vue_2.createVNode)((0, vue_2.unref)(material_1.CloseFilled))
                                ]),
                                _: 1
                            })
                        ])
                    ]))
            ]));
        };
    }
});
